<template>
    <!-- 历史记录下拉选择 -->
    <div>
        <van-dropdown-menu active-color="#4095E5">
            <van-dropdown-item v-model="categoryValue" :options="optionCategory" :title="categoryTitle"
                @change="categoryChange" />
            <van-dropdown-item :title="dateRangeTitle" @open="openCalendar" />
        </van-dropdown-menu>
        <van-calendar v-model="showCalendar" type="range" @confirm="onConfirm" color="#4095E5" :min-date="minDate"
            :max-date="maxDate" />
    </div>
</template>
<script>
export default {
    props: {
        optionCategory: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            categoryValue: '',
            showCalendar: false,
            dateRangeTitle: "选择日期范围",
            categoryTitle: "全部",
            minDate: new Date(2010, 1, 1),
            maxDate: new Date(),
        };
    },
    mounted() {
        // this.maxDate = new Date(this.$moment().format('YYYY'), this.$moment().format('MM'), this.$moment().format('DD'))
    },
    methods: {
        categoryChange(category) {
            this.categoryTitle = category
            this.$emit("selectCategory", category)
        },
        currentDate() {
            return this.$moment(new Date()).format('YYYY-MM-DD')
        },
        formatter(day) {
            const month = day.date.getMonth() + 1;
            const date = day.date.getDate();

            return day;
        },
        openCalendar() {
            this.showCalendar = true
        },
        formatDate(date) {
            return `${date.getMonth() + 1}/${date.getDate()}`;
        },
        formatDateValue(value) {
            return this.$moment(value).format('YYYY-MM-DD')
        },
        onConfirm(value) {
            this.showCalendar = false
            const [start, end] = value;
            const date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
            this.dateRangeTitle = date
            const start_value = this.formatDateValue(start)
            const end_value = this.formatDateValue(end)
            this.$emit("selectDateRange", start_value, end_value)
        }
    }
};
</script>