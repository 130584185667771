import storage from "store";
import expirePlugin from "store/plugins/expire";

storage.addPlugin(expirePlugin);
const antai = {
  state: {
    loading: false,
    page_loading: false,
    show_setting_dialog: false,
  },

  mutations: {
    SET_LOADING: (state, value) => {
      //
      state.loading = value;
    },
    SET_SETTING_DIALOG: (state, value) => {
      //
      state.show_setting_dialog = value;
    },
    SET_PAGE_DIALOG: (state, value) => {
      //
      state.page_loading = value;
    },
  },

  actions: {
    // 更新加载状态
    UpdateLoading(context, value) {
      context.commit("SET_LOADING", value);
    },
    SettingDialog(context, value) {
      context.commit("SET_SETTING_DIALOG", value);
    },
    PageLoadding(context, value) {
      context.commit("SET_PAGE_DIALOG", value);
    },
  },
};

export default antai;
