<template>
    <div style="height: max-content;">
        <van-row style="padding-bottom: 12%;position: fixed;bottom: 0;width: 100%;background-color:#fff" id="bottom_view">
            <van-col :span="24">
                <van-field :maxlength="2000" show-word-limit v-model="inputMessage" rows="2" type="textarea"
                    placeholder="请输入要发送的内容" />
            </van-col>
        </van-row>
        <van-goods-action ref="actions">
            <van-goods-action-icon @click="goLinkUs()">
                <template #icon>
                    <van-icon name="service-o" />
                </template>
                <template #default>
                    联系我们
                </template>
            </van-goods-action-icon>
            <van-goods-action-icon icon="clock-o" text="聊天记录" @click="chatHistory()" />
            <van-goods-action-button
                :disabled="filterInput() == '' || $store.state.user.user_info['api_tokens'] == 0 ? true : false"
                type="danger" icon="guide-o" color="#4095E5" @click="actionOpenai()">
                <template #default>
                    <van-badge :content="$store.state.user.user_info['api_tokens'] == -1 ? 'token无限制' : '可用token:' +
                        $store.state.user.user_info['api_tokens']">
                        发送
                    </van-badge>
                </template>
                <template #icon>
                    <van-icon class-prefix="antai" name="shuyi_fanyi-36" size="20" color="#000" />
                </template>
            </van-goods-action-button>
        </van-goods-action>
    </div>
</template>
<script>

export default {
    props: {
        assistant: {
            type: String,
            default: null
        },
        openaiVersion: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            inputMessage: ""
        }
    },
    methods: {
        clearInput() {
            this.inputMessage = ""
        },
        filterInput() {
            return this.inputMessage.trim()
        },
        goLinkUs() {
            // 联系我们
            window.open('https://www.itinfo.zj.cn/h-msgBoard.html', '_blank')
        },
        chatHistory() {
            this.$router.push('/history')
        },
        actionOpenai() {
            // console.log('发送成功', this.defaultSelect === "生成文案")
            let params = {}
            if (this.inputMessage == '') {
                this.$toast('输入文字不能为空');
                this.inputMessage = ''
                return
            }
            if (!this.assistant && this.openaiVersion != 'Dalle') {
                this.$toast('请先选择助手');
                return
            }
            if (!this.openaiVersion) {
                this.$toast('请先选择GPT版本');
                return
            }
            params['question'] = this.inputMessage
            params['openai_type'] = this.openaiVersion
            params['function_name'] = this.assistant
            this.$emit('sendMessage', params)
        }
    }
}
</script>
<style scoped lang="less">
/deep/.van-grid-item__text {
    font-size: 12px;
}

/deep/.van-grid-item__icon {
    font-size: 16px;
}
</style>