<template>
    <div>
        <van-empty v-if="chatList.length == 0" description="发起一段对话吧~" />
        <div v-else style="padding-top: 5%;">
            <ChatView ref="chat_view_message" :isSet="true" :chatList="chatList" @copyMethod="copyMethod"
                @insertMethod="insertMethod" />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        chatList: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    components: {
        ChatView: () => import('./chatView/ChatView.vue'),
    },
    methods: {
        setLoadding(flag) {
            this.$nextTick(() => {
                this.$refs.chat_view_message.setLoadding(flag)
                this.$refs.chat_view_message.handleScrollBottom()
            })
        },
        copyMethod(value) {
            // 复制方法
            this.$emit('copyMethod', value, 'text')
        },
        insertMethod(value) {
            // 插入方法
            this.$emit('insertMethod', value, 'text')
        }
    },
}
</script>