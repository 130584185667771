<template>
    <div style="height: 100vh;max-height: 100vh;margin-bottom: 15%;overflow-y: scroll;">
        <van-sticky :offset-top="45">
            <HistorySelectComponents @selectDateRange="selectDateRange" :optionCategory="optionCategory"
                @selectCategory="selectCategory" />
        </van-sticky>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text="下拉加载更多~" loosing-text="释放加载更多~">
            <div v-if="chatList.length > 0" style="padding-top: 5%;">
                <ChatView :isSet="false" :chatList="chatList" @copyMethod="copyMethod" @insertMethod="insertMethod"
                    :isHistory="true" />

            </div>
            <van-empty description="暂无历史记录" v-else>
                <van-button round type="info" class="bottom-button" @click="goHome">去使用copilot</van-button>
            </van-empty>
        </van-pull-refresh>
    </div>
</template>
<script>
import HistorySelectComponents from '@/components/HistorySelectComponents.vue';
import ChatView from '@/components/chatView/ChatView.vue';
import { ChatRecordHistory } from "@/api/openai";

export default {
    components: { HistorySelectComponents, ChatView },
    data() {
        return {
            optionCategory: [],
            params: {
                time_range: [],
                chat_type: "",
                has_count: 1,
                current: 1,
                size: 10,
                pageTotal: 0
            },
            isLoading: false,
            chatList: []
        }
    },
    methods: {
        copyMethod(value, type) {
            // 复制方法
             // 复制方法
             const clipboard = new this.clipboard('.copy-btn', {
                text() {
                return value
                }
            })
            clipboard.on('success', () => {
                this.$toast('文本已复制到剪贴板')
            })
            clipboard.on('error', () => {
                this.$toast('复制失败')
            })
        },
        insertMethod(value, type) {
            // 插入方法
            if (type === "imagine") {
                this.getBase64(value).then((thumbnail) => {
                    const base64 = thumbnail.split(",")[1];
                    window.Office.context.document.setSelectedDataAsync(
                    base64,
                {
                    coercionType: window.Office.CoercionType.Image,
                },
                (asyncResult) => {
                    if (asyncResult.status === window.Office.AsyncResultStatus.Failed) {
                        this.$toast('插入失败');
                    } else {
                        this.$toast('插入成功');
                    }
                }
                )
            })
            } else {
                window.Office.context.document.setSelectedDataAsync(value, { coercionType: 'text' })
                this.$toast('插入成功');
            }
        },
        goHome() {
            this.$router.push({ path: "/" })
        },
        selectDateRange(start_value, end_value) {
            // 日期筛选项
            this.params.time_range = [start_value + " 00:00:00", end_value + " 23:59:59"];
            // console.log('end_value', this.time_range)
            this.ChatRecord()
        },
        selectCategory(category) {
            this.params.chat_type = category
            this.ChatRecord()
        },
        ChatRecord() {
            if (this.params.current == 1) {
                this.chatList = []
            }
            ChatRecordHistory(this.params).then(res => {
                if (!res) return
                console.log('历史记录', res)
                const categoryList = []
                categoryList.push({ text: '全部', value: '' })
                for (let index = 0; index < res.metal.length; index++) {
                    const element = res.metal[index];
                    if (element != '') categoryList.push({ text: element, value: element })
                }
                res.list.forEach(element => {
                    this.addRecord(element)
                });
                console.log('categoryList', categoryList)
                this.params.pageTotal = res.page.total
                this.params.current = res.page.current
                this.optionCategory = categoryList
            });
        },
        addRecord(element) {
            // 创建对话
            this.chatList.unshift({
                content: element.answer,
                type: 'assistant',
                time: element.answer_time
            })
            this.chatList.unshift({
                content: element.question,
                type: 'user',
                time: element.question_time
            })
        },
        onRefresh() {
            setTimeout(() => {
                this.isLoading = false;
                this.params.current += 1
                if ((this.params.current * this.params.size) > this.params.pageTotal) {
                    this.$toast('没有更多了');
                    return
                }
                this.ChatRecord()
            }, 1000);
        },
        getBase64(url) {
            return new Promise((resolve, reject) => {
            // 通过构造函数来创建的 img 实例，在赋予 src 值后就会立刻下载图片，避免了文档冗余和污染
                let Img = new Image();
                Img.src = url;
                Img.crossOrigin = "Anonymous";
                Img.onload = () => {
                // 要先确保图片完整获取到，这是个异步事件
                let dataURL = "";
                let canvas = document.createElement("canvas"); // 创建canvas元素
                let width = Img.width; // 确保canvas的尺寸和图片一样
                let height = Img.height;
                canvas.width = width || 0;
                canvas.height = height || 0;
                let ctx = canvas.getContext("2d");
                if (ctx) ctx.drawImage(Img, 0, 0, width, height); // 将图片绘制到canvas中
                dataURL = canvas.toDataURL("image/jpeg"); // 转换图片为dataURL
                resolve(dataURL);
                }
            })
        }
    },
    mounted() {
        this.ChatRecord()
    },
}
</script>
<style scoped lang="less">
.bottom-button {
    width: 160px;
    background-color: #4095E5;
    height: 40px;
}
</style>