import request from '@/utils/request'

const userApi = {
  Login: '/account/login',
}

/**
 * @param parameter
 * @returns {*}
 */
export function login(parameter) {
  return request({
    url: userApi.Login,
    method: 'post',
    data: parameter,
  })
}
