<template>
    <div>
        <van-dropdown-menu active-color="#4095E5">
            <van-dropdown-item :title="defaultFuction" ref="selectFuction">
                <van-radio-group v-model="selectFuctionItem" style="max-height: 50%;">
                    <van-cell-group>
                        <van-cell :title="item.prompt_name" @click="assistantChange(item.prompt_name)" clickable
                            v-for="(item, key) in selectFuction" :key="key">
                            <template #right-icon>
                                <van-radio :name="item.prompt_name">
                                    <template #icon="props">
                                        <img class="check-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell>
                    </van-cell-group>
                </van-radio-group>
                <div style="padding: 5px 16px;">
                    <van-row gutter="10">
                        <van-col span="12">
                            <van-button :disabled="selectFuctionItem !== null ? false : true" type="default" size="small"
                                block round @click="settingDefault()">
                                设为默认
                            </van-button>
                        </van-col>
                        <van-col span="12">
                            <van-button :disabled="selectFuctionItem !== null ? false : true" color="#4095E5" type="danger"
                                size="small" block round @click="selectFuctionBtn">
                                选择功能
                            </van-button>
                        </van-col>
                    </van-row>
                </div>
            </van-dropdown-item>
            <van-dropdown-item v-model="value" ref="selectGpt" :title="defaultGpt == 'Dalle' ? '文生图' : defaultGpt">
                <van-radio-group v-model="selectGptItem" style="max-height: 50%;">
                    <van-cell-group>
                        <van-cell :title="item.text == 'Dalle' ? '文生图' : item.text" @click="gptChange(key)" clickable
                            v-for="(item, key) in gptVersion" :key="key">
                            <template #icon v-if="!filterVip(item.text)">
                                <van-icon name="vip-card-o" color="#ffca28" size="24" />&nbsp;(vip可用)
                            </template>
                            <template #right-icon>
                                <van-radio :name="key">
                                    <template #icon="props">
                                        <img class="check-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell>
                    </van-cell-group>
                </van-radio-group>
            </van-dropdown-item>
        </van-dropdown-menu>
    </div>
</template>
<script>
import { GetConfigs } from '@/api/api';
import { SetDefaultFunc } from '@/api/api'
import { GetAccountInfo } from '@/api/api';

export default {
    props: {
        selectFuction: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            activeIcon: require('@/assets/checked.png'),
            inactiveIcon: require('@/assets/un_checked.png'),
            value: 0,
            switch1: false,
            switch2: false,
            selectFuctionItem: null,
            selectFuctionObj: {},
            selectGptItem: null,
            defaultGpt: 'GPT3',
            defaultFuction: '选择助手',
            gptVersion: [
                { text: 'GPT3', value: 0 },
                { text: 'GPT4', value: 1 },
                { text: 'Dalle', value: 2 },
            ],
            userGpt: []
            // selectFuction: [
            // ],
        }
    },
    mounted() {
        this.getConfigs('openai_category')
    },
    methods: {
        getUserInfo() {
            GetAccountInfo().then(res => {
                if (!res) return
                console.log('更新用户信息', res)
                this.$store.dispatch('updateUserInfo', res)
            })
        },
        getConfigs(config_name) {
            GetConfigs({ config_name: config_name }).then(res => {
                if (!res) return
                console.log('openai_category', res)
                this.gptVersion = []
                res.forEach(element => {
                    this.gptVersion.push({ text: element, value: element })
                });
                // this.Configs = res;
                // this.toneColums = []
                // this.lengthColums = []
                // res.tone.forEach(element => {

                //     this.toneColums.push(element['name'] || element)
                // });
                // res.length.forEach(element => {
                //     this.lengthColums.push(element['name'] || element)
                // });
                // this.selectTone = this.toneColums[0]
                // this.selectLength = this.lengthColums[0]
            })
        },
        updateDefault(defaultItem) {
            this.defaultFuction = defaultItem || "选择助手"
            this.selectFuctionItem = defaultItem
            this.selectFuctionObj = this.selectFuction.find(res => {
                return res.prompt_name == defaultItem
            })
        },
        settingDefault() {
            // 设置默认功能
            this.defaultFuction = this.selectFuctionItem || "选择助手"
            this.selectFuctionObj = this.selectFuction.find(res => {
                return res.prompt_name == this.selectFuctionItem
            })
            SetDefaultFunc({ default_func: this.selectFuctionObj }).then(res => {
                this.$emit('updateUserInfo')
            }).finally(() => {
                this.$toast('设置成功')
                this.$refs.selectFuction.toggle(false)
            })
        },
        setUserGpt(userGpt) {
            this.userGpt = userGpt
            this.gptChange(0)
        },
        filterVip(name) {
            // 过滤是否存在vip选项
            // const openai_category = this.$store.state.user.user_info['openai_category'] || []
            var ar = this.userGpt.find(function (elem) {
                return elem === name;
            });
            return ar
        },
        assistantChange(value) {
            // 修改助手
            this.selectFuctionItem = value
        },
        gptChange(value) {
            // gpt版本切换
            const is_vip = this.filterVip(this.gptVersion[value]['text'])
            if (!is_vip) {
                // this.$toast('该功能仅对VIP开放')
                return
            }
            this.selectGptItem = value
            this.$refs.selectGpt.toggle(false)
            this.defaultGpt = this.gptVersion[value]['text']
            // 选择openai版本
            this.$emit('selectOpenaiVersion', this.defaultGpt)
            console.log('this.userGpt', this.userGpt)
        },
        selectFuctionBtn() {
            // 选择功能
            this.$refs.selectFuction.toggle(false)
            this.defaultFuction = this.selectFuctionItem || "选择助手"
            if (this.selectFuction) {
                const selectObj = this.selectFuction.find(item => {
                    return item.prompt_name == this.defaultFuction
                })
                if (this.defaultGpt == "Dalle") {
                    this.defaultGpt = this.userGpt[0]
                    this.selectGptItem = 0
                    this.$emit('selectOpenaiVersion', this.defaultGpt)
                }
                this.$emit('selectAssistant', selectObj)
            }
        }
    },
}
</script>
<style lang="less" scoped>
.check-icon {
    width: 20px;
}

/deep/.custom-image .van-empty__image {
    width: 20%;
    height: 20%;
}
</style>