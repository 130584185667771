<template>
    <div style="text-align: center;background-color: #f0f2f5;height: 100%;">
        <div class="her">
            <van-row align="left">
                <van-col span="24" style="margin-top: 20pt;font-size:22pt;text-align: center;padding-bottom: 10pt;">
                    <van-image :src="LogoImg" width="30vh"></van-image>
                </van-col>
                <van-col span="24" style="text-align: center;padding-bottom: 20pt;">让每一个用户的信息系统更有价值
                </van-col>
            </van-row>
        </div>
        <van-tabs v-model="activeTab" color="#4095E5" background="#f0f2f5">
            <van-tab title="账号密码">
                <van-row type="flex" justify="center">
                    <van-col span="20">
                        <van-form @submit="login">
                            <van-cell-group class="bju">
                                <van-field :rules="[{ required: true, message: '请填写用户名' }]" autocomplete="new-user"
                                    size="large" :colon="true" left-icon="phone" :autofocus="false" v-model="login_name"
                                    name="login_name" placeholder="请输入手机号码" clearable @keyup.enter="login" />
                                <van-field :rules="[{ required: true, message: '请填写密码' }]" autocomplete="new-password"
                                    :colon="true" name="password" left-icon="lock" size="large" :autofocus="false"
                                    v-model="password" type="password" placeholder="请输入密码" @keyup.enter="login" />
                            </van-cell-group>
                            <van-button type="primary" size="small" class="btn bju" block :disabled="islogin"
                                :loading="islogin" :loading-text="isloginTxt" native-type="submit">
                                登录
                            </van-button>
                        </van-form>
                    </van-col>
                </van-row>
            </van-tab>
        </van-tabs>
        <div class="footer">
            <div class="copyright">
                <a href="https://www.itinfo.zj.cn/h-msgBoard.html" target="_blank">联系我们</a>
            </div>
        </div>
    </div>
</template>
<script>
import { login } from '@/api/login'
import { GetAccountInfo } from '@/api/api';
import store from '@/store'
import storage from "store";
export default {
    data() {
        return {
            LogoImg: require('@/assets/antai_logo_info.png'),
            activeTab: 0,
            login_name: '',
            password: '',
            islogin: false,
            isloginTxt: '',
        }
    },
    created() {
    },
    methods: {
        login(values) {
            this.islogin = true;
            this.isloginTxt = '正在登陆...';
            login(values).then((res) => {
                if (!res) return
                console.log(res)
                store.dispatch('updateToken', res.token)
                // 获取用户信息
                this.getUserInfo()
                // localStorage.setItem('uid', data.data.id);
                // localStorage.setItem('uphone', data.data.phone);
                // var openid = localStorage.getItem('wxopenid');
                // if (openid) {
                //     UserBWx(data.data.id, openid).then(function (data) {
                //     });
                // }
            }).finally(() => {
                this.islogin = false;
                this.isloginTxt = '登录';
            });
        },
        getUserInfo() {
            GetAccountInfo().then(res => {
                if (!res) return
                store.dispatch('updateUserInfo', res)
                setTimeout(() => {
                    this.$toast('登陆成功！' + res.real_name);
                    this.$router.push('/');
                }, 300)
            })
        }
    }
}
</script>

<style scoped lang="less">
@import url('login.less');
</style>
