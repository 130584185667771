import storage from "store";
import expirePlugin from "store/plugins/expire";
import { login, getInfo, logout } from "@/api/login";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import { welcome } from "@/utils/util";

storage.addPlugin(expirePlugin);
const user = {
  state: {
    token: "",
    user_info: {},
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USER_INFO: (state, value) => {
      //
      state.user_info = value;
    },
  },

  actions: {
    updateToken({ commit }, token) {
      //更新用户token
      commit("SET_TOKEN", token);
      storage.set("token", token);
    },
    updateUserInfo({ commit }, userInfo) {
      // 更新用户信息z
      commit("SET_USER_INFO", userInfo);
      storage.set("user_info", userInfo);
    },
  },
};

export default user;
