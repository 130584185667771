<template>
    <div>
        <van-row v-if="columns.length > 0" justify="center" style="margin-left: 14px;margin-right: 14px;">
            <van-col :span="24" style="margin-top: 5%;border-radius: 4px;">
                <van-field required readonly clickable name="picker" :value="selectOption" label="选项" placeholder="点击选择"
                    @click="showPicker = true" />
            </van-col>
        </van-row>
        <van-popup v-model="showPicker" position="bottom">
            <van-picker show-toolbar :columns="columns" @confirm="onSelectOption" @cancel="showPicker = false" />
        </van-popup>
        <div class="chatList">
            <van-empty v-if="chatList.length == 0" :description="pageConfig.prompt_tips" />
            <div v-else style="padding-top: 5%;">
                <ChatView ref="chat_view_general" :isSet="true" :chatList="chatList" @copyMethod="copyMethod"
                    @insertMethod="insertMethod" style="padding-bottom: 20%;" />
            </div>
        </div>
    </div>
</template>
 <!-- 通用消息组件 -->
<script>
// import
import { GetConfigs } from '@/api/api'
export default {
    components: {
        ChatView: () => import('./chatView/ChatView.vue'),
    },
    props: {
        chatList: {
            type: Array,
            default: () => {
                return []
            }
        },
        pageConfig: {
            type: Object,
            default: () => {
                return { prompt_tips: "让助手帮您做点什么吧~" }
            }
        }
    },
    watch: {
        pageConfig: {
            handler(new_val, old_val) {
                if (!new_val) return
                const prompt_name = new_val.prompt_name || undefined
                if (prompt_name) {
                    this._GetConfigs(prompt_name)
                }
            },
            immediate: true
        }
    },
    data() {
        return {
            selectOption: '',
            columns: [],
            showPicker: false,
        };
    },
    methods: {
        copyMethod(value) {
            // 复制方法
            this.$emit('copyMethod', value, 'text')
        },
        insertMethod(value) {
            // 插入方法
            this.$emit('insertMethod', value, 'text')
        },
        setLoadding(flag) {
            this.$nextTick(() => {
                this.$refs.chat_view_general.setLoadding(flag)
            })
        },
        onSelectOption(value) {
            this.selectOption = value;
            this.showPicker = false;
            this.$emit('generalOptionChanged', value)
        },
        _GetConfigs(config_name) {
            this.selectOption = ''
            // 获取系统配置
            GetConfigs({ config_name: config_name }).then((res) => {
                if (!res) {
                    this.columns = []
                } else {
                    this.columns = []
                    res.forEach(element => {
                        this.columns.push(element['name'] || element)
                    });
                    this.selectOption = res[0]['name'] || res[0]
                    this.onSelectOption(this.selectOption)
                }
            })
        },
    }
};
</script>
<style scoped lang="less">
.van-cell--clickable {
    border-radius: 4px;
}
</style>