import request from '@/utils/request'
// import request_noloading from "@/utils/request_noloading"
const openai = {
    chatCompletions: '/openai/chat_completions',//发起对话
    TopicList: '/openai/topic_list',//主题列表
    ChatRecord: '/openai/chat_record',//获取聊天记录
    ImagineRecord: '/openai/imagine_record',
    chatHistory: '/openai/chat_record_history',//聊天记录历史
}
//聊天记录历史
export function ChatRecordHistory(parameter) {
    return request({
        url: openai.chatHistory,
        method: 'get',
        params: parameter,
    })
}
// 生图记录
export function ImagineRecord(parameter) {
    return request({
        url: openai.ImagineRecord,
        method: 'get',
        params: parameter,
    })
}
// 聊天
export function ChatCompletions(parameter) {
    return request({
        url: openai.chatCompletions,
        method: 'post',
        data: parameter,
    })
}
// 主题列表
export function TopicList(parameter) {
    return request({
        url: openai.TopicList,
        method: 'get',
        params: parameter,
    })
}
// 聊天记录
export function ChatRecord(parameter) {
    return request({
        url: openai.ChatRecord,
        method: 'get',
        params: parameter,
    })
}