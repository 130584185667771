<template>
    <div class="home_class" ref="home_class">
        <van-sticky :offset-top="45">
            <HomeSelectComponents @selectAssistant="selectAssistant" @selectOpenaiVersion="selectOpenaiVersion"
                :selectFuction="assistantList" @updateUserInfo="updateUserInfo" ref="HomeSelectComponents" />
        </van-sticky>
        <div :class="current_assistant.prompt_name == '文案生成' ? 'content-zero' : 'content'" ref="content_view">
            <div v-if="current_openai_version == 'Dalle'">
                <ImagineComponent ref="ImagineComponent" :chatList="chatRecord" @insertMethod="insertMethod" />
            </div>
            <div v-else>
                <van-empty v-if="current_assistant == {}" class="custom-image" description="请从选择一个助手开始吧" />
                <div v-else>
                    <ChatComponent v-if="current_assistant.prompt_name == '聊天'" ref="ChatComponent" :chatList="chatRecord"
                        @copyMethod="copyMethod" @insertMethod="insertMethod" />
                    <CopywritingComponents v-else-if="current_assistant.prompt_name == '文案生成'"
                        :assistant="current_assistant.prompt_name" :openaiVersion="current_openai_version"
                        @copyMethod="copyMethod" @insertMethod="insertMethod" />
                    <GeneralMessageComponent v-else ref="GeneralMessage" :pageConfig="current_assistant"
                        :chatList="chatRecord" @generalOptionChanged="generalOptionChanged" @copyMethod="copyMethod"
                        @insertMethod="insertMethod" />
                </div>
            </div>
        </div>
        <div ref="bottom_view">
            <BottomComponent v-if="!is_hidden_bottom" @sendMessage="sendMessage" :assistant="current_assistant.prompt_name"
                :openaiVersion="current_openai_version" ref="BottomComponent" />
        </div>
    </div>
</template>
<script>
import { GetAccountInfo } from '@/api/api';
import storage from "store";
import BottomComponent from '@/components/BottomComponent.vue';//底部组件
import HomeSelectComponents from '@/components/HomeSelectComponents.vue';//首页选择组件
import GeneralMessageComponent from '@/components/GeneralMessageComponent.vue';//通用组件
import ChatComponent from '@/components/ChatComponent.vue';//聊天组件
import CopywritingComponents from '@/components/CopywritingComponents.vue';//文案生成
import { ChatCompletions } from '@/api/openai'
import ImagineComponent from '@/components/ImagineComponent.vue';

export default {
    components: {
        HomeSelectComponents,//首页选择组件
        BottomComponent,//底部组件
        GeneralMessageComponent,//通用组件
        ChatComponent,//聊天组件
        CopywritingComponents,//文案生成
        ImagineComponent
    },
    computed: {
    },
    data() {
        return {
            windowHeight: document.body.clientHeight,
            windowWidth: document.body.clientWidth,
            current_assistant: {},
            current_openai_version: "GPT3",//当前版本
            chatRecord: [],
            assistantList: [],
            is_update_config: true,
            is_hidden_bottom: false
        };
    },
    watch: {
        // 监听页面高度
        is_hidden_bottom(val) {
            this.disposeHeight()
        },
        current_assistant: {
            handler(new_val, old_val) {
                if (!new_val.prompt_name) return
                if (new_val.prompt_name == '文案生成') {
                    this.is_hidden_bottom = true
                } else {
                    this.is_hidden_bottom = false
                }
                console.log('current_assistant log info', new_val)
            },
            immediate: true,
            deep: true
        }
    },
    mounted() {
        this.getUserInfo()
    },
    updated() {
        this.disposeHeight()
    },
    methods: {
        disposeHeight() {
            this.$nextTick(() => {
                // const bottomHeight = this.$refs.BottomComponent.$el.querySelector(':first-child').offsetHeight;
                // console.log('BottomComponent', this.$refs.BottomComponent.$el.offsetHeight)
                // this.$refs.content_view.style.height = bottomHeight + "px"
            })
        },
        copyMethod(value, type) {
            // 复制方法
            const clipboard = new this.clipboard('.copy-btn', {
                text() {
                return value
                }
            })
            clipboard.on('success', () => {
                this.$toast('文本已复制到剪贴板')
            })
            clipboard.on('error', () => {
                this.$toast('复制失败')
            })
        },
        insertMethod(value, type) {
            // 插入方法
            if (type === "imagine") {
                this.getBase64(value).then((thumbnail) => {
                    const base64 = thumbnail.split(",")[1];
                    window.Office.context.document.setSelectedDataAsync(
                    base64,
                {
                    coercionType: window.Office.CoercionType.Image,
                },
                (asyncResult) => {
                    if (asyncResult.status === window.Office.AsyncResultStatus.Failed) {
                        this.$toast('插入失败');
                    } else {
                        this.$toast('插入成功');
                    }
                }
                )
            })
            } else {
                window.Office.context.document.setSelectedDataAsync(value, { coercionType: 'text' })
                this.$toast('插入成功');
            }
            
        },
        addRecord(element) {
            // 创建对话
            this.chatRecord.unshift({
                content: element.answer,
                type: 'assistant',
                time: element.answer_time
            })
            this.chatRecord.unshift({
                content: element.question,
                type: 'user',
                time: element.question_time
            })
        },
        updateUserInfo() {
            this.getUserInfo()
        },
        assembleParams(question_str) {
            // 通用聊天组件
            const selectOption = this.$refs.GeneralMessage.selectOption
            let question;
            if (selectOption) {
                question = `请将${question_str}，${this.current_assistant.prompt_name}为${selectOption}`
            } else {
                question = `请将${question_str}，进行${this.current_assistant.prompt_name}`
            }
            const systemTip = this.current_assistant.prompt_content
            return { systemTip: systemTip, question: question }
        },
        wordWrite() {
            // 组装文案生成参数
            const caseTone = this.$refs.SpecialPrompt.select_tone
            const caseLength = this.$refs.SpecialPrompt.select_length
            const systemTip = this.special_prompt.system_tips // 系统提示
            const question = `文案意图:${this.chat_message},文案风格:${caseTone},文案长度:${caseLength}`
            return { systemTip: systemTip, question: question }
        },
        getUserInfo() {
            GetAccountInfo().then(res => {
                if (!res) return
                this.$store.dispatch('updateUserInfo', res)
                const user_default_function = res.user_default_function
                this.assistantList = [...this.$store.state.user.user_info['company_prompt'] || [], { prompt_name: '聊天' }, { prompt_name: '文案生成' }];
                if (user_default_function) {
                    this.current_assistant = res.user_default_function
                    this.$nextTick(() => {
                        console.log('更新用户首选', this.current_assistant)
                        // 更新用户默认选项
                        this.$refs.HomeSelectComponents.updateDefault(this.current_assistant.prompt_name)
                        this.$refs.HomeSelectComponents.setUserGpt(res.openai_category)
                    })
                }
            })
        },
        generalOptionChanged(value) {
            // 通用组件事件
            console.log('通用组件事件', value)
        },
        loaddiungView(flag) {
            setTimeout(() => {
                this.$nextTick(() => {
                    if (this.$refs.ChatComponent) this.$refs.ChatComponent.setLoadding(flag)
                    if (this.$refs.GeneralMessage) this.$refs.GeneralMessage.setLoadding(flag)
                    if (this.$refs.ImagineComponent) this.$refs.ImagineComponent.setLoadding(flag)
                })
            }, 200)
        },
        sendMessage(params) {
            // 发送消息方法
            let messageObj = {}
            switch (this.current_openai_version) {
                case 'Dalle':
                    this.loaddiungView(true)
                    break
                default:
                    switch (this.current_assistant.prompt_name) {
                        case '生成文案':
                            messageObj = this.wordWrite()
                            params.question = messageObj.question
                            break;
                        case '聊天':
                            // TODO
                            this.loaddiungView(true)
                            break;
                        default:
                            messageObj = this.assembleParams(params.question)
                            this.loaddiungView(true)
                            params.question = messageObj.question
                            break;
                    }
                    break
            }
            this.chatRecord.push({
                content: params.question,
                type: 'user'
            })
            params['systemTip'] = messageObj['systemTip'] || '';
            this.$refs.BottomComponent.clearInput()
            this.handleScrollBottom()
            // 请求数据
            ChatCompletions(params).then((res) => {
                this.$nextTick(() => {
                    if (this.$refs.GeneralMessage) this.$refs.GeneralMessage.setLoadding(false)
                    if (this.$refs.ChatComponent) this.$refs.ChatComponent.setLoadding(false)
                })
                if (!res) return
                // this.topic_id = res.topic_id
                this.is_update_config = false
                this.updateUserScore()
                if (this.current_openai_version !== 'Dalle') {
                    if (this.current_assistant === '生成文案') {
                        this.answer = res.answer
                    } else {
                        this.chatRecord.push({
                            content: res.answer,
                            type: 'assistant'
                        })
                        this.$refs.BottomComponent.clearInput()
                    }
                } else {
                    // console.log('res.answer.imagines[0]', res.answer.imagines[0]);
                    const imagine = res['imagines']
                    if (imagine.length === 0) {
                        this.$toast('图像生成失败,请重试')
                    }
                    this.chatRecord.push({
                        content: imagine[0],
                        type: 'assistant'
                    })
                    this.$refs.BottomComponent.clearInput()
                }
            }).finally(() => {
                this.loaddiungView(false)
                this.handleScrollBottom()
            })
        },
        handleScrollBottom() {
            // 滚动到底部
            this.$nextTick(() => {
                const scrollElem = this.$refs.content_view
                scrollElem.scrollTo({ top: scrollElem.scrollHeight + 800, behavior: 'smooth' })
            })
        },
        updateUserScore() {
            GetAccountInfo().then(res => {
                if (!res) return
                this.$store.dispatch('updateUserInfo', res)
            })
        },
        selectAssistant(assistant) {
            // 助手选择回调
            this.current_assistant = assistant
            this.chatRecord = []
        },
        selectOpenaiVersion(openaiVersion) {
            this.current_openai_version = openaiVersion
            if (openaiVersion == "Dalle") {
                this.is_hidden_bottom = false
                this.$nextTick(() => {
                    this.$refs.content_view.style.height = "80%"
                })
            }
        },
        getBase64(url) {
            return new Promise((resolve, reject) => {
            // 通过构造函数来创建的 img 实例，在赋予 src 值后就会立刻下载图片，避免了文档冗余和污染
                let Img = new Image();
                Img.src = url;
                Img.crossOrigin = "Anonymous";
                Img.onload = () => {
                // 要先确保图片完整获取到，这是个异步事件
                let dataURL = "";
                let canvas = document.createElement("canvas"); // 创建canvas元素
                let width = Img.width; // 确保canvas的尺寸和图片一样
                let height = Img.height;
                canvas.width = width || 0;
                canvas.height = height || 0;
                let ctx = canvas.getContext("2d");
                if (ctx) ctx.drawImage(Img, 0, 0, width, height); // 将图片绘制到canvas中
                dataURL = canvas.toDataURL("image/jpeg"); // 转换图片为dataURL
                resolve(dataURL);
                }
            })
        }
    },
};
</script>
<style lang="less" scoped>
@extra: 200px;
@extraZero: 0px;

.content {
    background-color: #f7f7f7cc;
    overflow-y: scroll !important;
    // padding-bottom: 20%;
    overflow-y: scroll;
    margin: 0 auto;
    height: calc(~"100% -"(@extra));
    max-height: calc(~"100% -"(@extra));
    // margin-bottom: calc();
}

.content-zero {
    background-color: #f7f7f7cc;
    overflow-y: scroll !important;
    // padding-bottom: 20%;
    overflow-y: scroll;
    margin: 0 auto;
    height: calc(~"100% -"(@extraZero));
    max-height: calc(~"100% -"(@extraZero));
    // margin-bottom: calc();
}

.home_class {
    min-height: 100%;
    height: 100%;
    max-height: 100%;
}
</style>