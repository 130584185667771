<template>
    <div>
        <div style="text-align: center;margin-top: 5%;">
            <van-image round width="7rem" height="7rem" :src="logo" />
            <p>{{ UserInfo.real_name }}</p>
        </div>
        <van-cell-group style="margin-top: 5%;">
            <van-field label="可用token" :value="UserInfo.api_tokens" readonly />
            <van-field label="企业名称" :value="UserInfo.company_name" readonly />
            <van-field label="手机号码" :value="UserInfo.phone" readonly />
        </van-cell-group>
        <div style="display: flex;justify-content: center;margin-top: 10%;">
            <van-button type="danger" color="#e50012" block style="width: 80%;" round @click="loginOut()">退出登录</van-button>
        </div>
        <div class="footer">
            <div class="copyright">
                <a href="https://www.itinfo.zj.cn/h-msgBoard.html" target="_blank">联系我们</a>
            </div>
        </div>
    </div>
</template>
<script>
import storage from "store"
import { Dialog } from 'vant';
export default {
    data() {
        return {
            logo: require('@/assets/antai_logo.png'),
        }
    },
    computed: {
        UserInfo() {
            return this.$store.state.user.user_info || null
        }
    },
    mounted() {
        console.log('UserInfo', this.UserInfo)
    },
    methods: {
        loginOut() {
            // 退出登录
            Dialog.confirm({
                title: '提示',
                message: '确认要退出登录吗?',
            })
                .then(() => {
                    storage.remove('token')
                    storage.remove('user_info')
                    this.$router.push('/login');
                })
                .catch(() => {
                    // on cancel
                });
        }
    }
}
</script>