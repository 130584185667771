import Home from "@/views/Home.vue";
import AccountVue from "@/views/account/account.vue";
import historyVue from "@/views/history/history.vue";
import Login from "@/views/login/Login.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      show_head: true,
      show_foot: true,
      needLogin: true,
      show_left: false,
      show_right: true,
    },
  },
  {
    path: "/history",
    name: "history",
    component: historyVue,
    meta: {
      show_head: true,
      title: "历史记录",
      show_foot: false,
      needLogin: true,
      show_left: true,
      show_right: false,
    },
  },
  {
    path: "/account",
    name: "account",
    component: AccountVue,
    meta: {
      show_head: true,
      title: "个人中心",
      show_foot: false,
      needLogin: true,
      show_left: true,
      show_right: false,
    },
  },
  {
    path: "/login",
    name: "login",
    meta: { show_head: false, show_foot: false, needLogin: false },
    component: Login,
  },
];
export default routes;
