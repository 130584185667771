import request from "@/utils/request";
// import request_noloading from "@/utils/request_noloading";
// ---------------------------getconfig-------------------------------------
const config_api = {
  // 管理员列表
  get_configs: "/get_configs",
};

export function GetConfigs(parameter) {
  return request({
    url: config_api.get_configs,
    method: "post",
    params: parameter,
  });
}
// ---------------------------account-------------------------------------
const account = {
  // 获取用户信息
  GetAccountInfo: "/account/get_account_info",
  SetDefaultFunc: "/account/set_default_func",
};
// 获取用户信息
export function SetDefaultFunc(parameter) {
  return request({
    url: account.SetDefaultFunc,
    method: "post",
    data: parameter,
  });
}
// 获取用户信息
export function GetAccountInfo(parameter) {
  return request({
    url: account.GetAccountInfo,
    method: "get",
    params: parameter,
  });
}
// -----------------------------Models-----------------------------------
const models = {
  // models列表
  AdminModelList: "/backend/model/model_list",
  // 新增/修改models
  SaveModel: "/backend/model/save_model",
  //
  DeleteModel: "/backend/model/delete_model",
};
// models列表
export function AdminModelList(parameter) {
  return request({
    url: models.AdminModelList,
    method: "get",
    params: parameter,
  });
}
// 新增models
export function SaveModel(parameter) {
  return request({
    url: models.SaveModel,
    method: "post",
    data: parameter,
  });
}
// 删除models
export function DeleteModel(parameter) {
  return request({
    url: models.DeleteModel,
    method: "post",
    data: parameter,
  });
}
// -----------------------------Prompt-----------------------------------
const prompt = {
  // prompt列表
  AdminPromptList: "/backend/prompt/prompt_list",
  // 新增/修改prompt
  SavePrompt: "/backend/prompt/save_prompt",
  //
  DeletePrompt: "/backend/prompt/delete_prompt",
};
// prompt列表
export function AdminPromptList(parameter) {
  return request({
    url: prompt.AdminPromptList,
    method: "get",
    params: parameter,
  });
}
// 新增prompt
export function SavePrompt(parameter) {
  return request({
    url: prompt.SavePrompt,
    method: "post",
    data: parameter,
  });
}
// 删除prompt
export function DeletePrompt(parameter) {
  return request({
    url: prompt.DeletePrompt,
    method: "post",
    data: parameter,
  });
}
