<template>
    <div>
        <van-row justify="center" style="margin-left: 14px;margin-right: 14px;">
            <van-col :span="24" style="margin-top: 5%;border-radius: 4px;">
                <span class="field_title">文案意图</span>
                <van-field v-model="chat_message" :maxlength="2000" show-word-limit rows="3" type="textarea"
                    placeholder="请输入你的意图，意图描述的越清楚，生成的文本越符合您的想法" />
            </van-col>
            <van-col :span="24" style="margin-top: 5%;border-radius: 4px;">
                <span class="field_title">文案风格</span>
                <van-field readonly clickable name="picker" :value="selectTone" placeholder="点击选择"
                    @click="showTonePicker = true" />
            </van-col>
            <van-col :span="24" style="margin-top: 5%;border-radius: 4px;">
                <span class="field_title">文案长度</span>
                <van-field readonly clickable name="picker" :value="selectLength" placeholder="点击选择"
                    @click="showLenPicker = true" />
            </van-col>
        </van-row>
        <div>
            <van-empty v-if="showEmpty" />
            <div v-else style="color: #646566;font-size: 14px;padding-top: 5%;padding-left: 16px;padding-right: 16px;">
                <van-row :gutter="20" v-if="!loading">
                    <van-col :span="12">
                        <van-button type="info" icon="notes" block size="small" color="#4095E5" class="copy-btn" 
                            @click="copyMethod(chatAnswer)">复制</van-button>
                    </van-col>
                    <van-col :span="12">
                        <van-button type="info" icon="plus" block size="small" color="#4095E5"
                            @click="insertMethod(chatAnswer)">插入</van-button>
                    </van-col>
                </van-row>

                <div class="answer-content">
                    <div v-if="loading">
                        <vant-row>
                            <van-col :span="2">
                                <van-loading size="20px" text-color="#4095E5" color="#4095E5"></van-loading>
                            </van-col>
                            <van-col :span="22">
                                生成中,请稍后...
                            </van-col>
                        </vant-row>
                    </div>
                    <div v-else style="max-height: 20vh;padding-bottom: 5px;">
                        {{ chatAnswer }}
                    </div>
                </div>
            </div>
        </div>
        <van-popup v-model="showLenPicker" position="bottom">
            <van-picker show-toolbar :columns="lengthColums" @confirm="onSelectLength" @cancel="showLenPicker = false" />
        </van-popup>
        <van-popup v-model="showTonePicker" position="bottom">
            <van-picker show-toolbar :columns="toneColums" @confirm="onSelectTone" @cancel="showTonePicker = false" />
        </van-popup>
        <div>
            <van-goods-action ref="actions">
                <van-goods-action-icon @click="goLinkUs()">
                    <template #icon>
                        <van-icon name="service-o" />
                    </template>
                    <template #default>
                        联系我们
                    </template>
                </van-goods-action-icon>
                <van-goods-action-icon icon="clock-o" text="聊天记录" @click="chatHistory()" />
                <van-goods-action-button
                    :disabled="filterInput() == '' || $store.state.user.user_info['api_tokens'] == 0 ? true : false"
                    type="danger" icon="guide-o" color="#4095E5" @click="actionOpenai()">
                    <template #default>
                        <van-badge :content="$store.state.user.user_info['api_tokens'] == -1 ? 'token无限制' : '可用token:' +
                            $store.state.user.user_info['api_tokens']">
                            {{ filterInput() !== '' && chatAnswer != '' ? '再来一次' : '发送' }}
                        </van-badge>
                    </template>
                    <template #icon>
                        <van-icon class-prefix="antai" name="shuyi_fanyi-36" size="20" color="#000" />
                    </template>
                </van-goods-action-button>
            </van-goods-action>
        </div>
    </div>
</template>
<!--文案助手  -->
<script>
import { GetAccountInfo } from '@/api/api';
import { ChatCompletions } from '@/api/openai'
import { GetConfigs } from '@/api/api';
export default {
    props: {
        assistant: {
            type: String,
            default: null
        },
        openaiVersion: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            chat_message: "",
            Configs: {},
            showLenPicker: false,
            showTonePicker: false,
            selectTone: "",
            selectLength: "",
            toneColums: [],
            lengthColums: [],
            chatAnswer: '',
            loading: false,
            showEmpty: true
        }
    },
    mounted() {
        this.getConfigs('special_prompt')
    },
    methods: {
        copyMethod(value, type) {
            // 复制方法
            const clipboard = new this.clipboard('.copy-btn', {
                text() {
                return value
                }
            })
            clipboard.on('success', () => {
                this.$toast('文本已复制到剪贴板')
            })
            clipboard.on('error', () => {
                this.$toast('复制失败')
            })
        },
        insertMethod(value, type) {
            // 插入方法
            window.Office.context.document.setSelectedDataAsync(value, { coercionType: 'text' })
            this.$toast('插入成功')
        },
        updateUserScore() {
            GetAccountInfo().then(res => {
                if (!res) return
                this.$store.dispatch('updateUserInfo', res)
            })
        },
        goLinkUs() {
            // 联系我们
            window.open('https://www.itinfo.zj.cn/h-msgBoard.html', '_blank')
        },
        chatHistory() {
            this.$router.push('/history')
        },
        clearInput() {
            this.inputMessage = ""
        },
        filterInput() {
            return this.chat_message.trim()
        },
        actionOpenai() {
            let params = {}
            const wordWrite = this.wordWrite()
            params['systemTip'] = wordWrite['systemTip'];
            params['question'] = wordWrite['question'];
            params['openai_type'] = this.openaiVersion
            params['function_name'] = this.assistant
            this.showEmpty = false
            this.loading = true
            ChatCompletions(params).then((res) => {
                this.chatAnswer = res.answer
            }).finally(() => {
                this.loading = false
                this.updateUserScore()
            })
        },
        onSelectLength(value) {
            this.selectLength = value
            this.showLenPicker = false;
        },
        onSelectTone(value) {
            this.selectTone = value
            this.showTonePicker = false;
        },
        wordWrite() {
            // 组装文案生成参数
            // const caseTone = this.$refs.SpecialPrompt.select_tone
            // const caseLength = this.$refs.SpecialPrompt.select_length
            const systemTip = this.Configs.system_tips // 系统提示
            const question = `文案意图:${this.chat_message},文案风格:${this.selectTone},文案长度:${this.selectLength}`
            return { systemTip: systemTip, question: question }
        },
        getConfigs(config_name) {
            GetConfigs({ config_name: config_name }).then(res => {
                if (!res) return
                console.log('配置信息', res)
                this.Configs = res;
                this.toneColums = []
                this.lengthColums = []
                res.tone.forEach(element => {

                    this.toneColums.push(element['name'] || element)
                });
                res.length.forEach(element => {
                    this.lengthColums.push(element['name'] || element)
                });
                this.selectTone = this.toneColums[0]
                this.selectLength = this.lengthColums[0]
            })
        },
    },
}
</script>
<style scoped lang="less">
.field_title {
    font-size: 14px;
    color: #999;
}

.van-empty {
    padding: unset;
}

/deep/.van-grid-item__text {
    font-size: 12px;
}

/deep/.van-grid-item__icon {
    font-size: 16px;
}

.answer-content {
    font-family: Arial;
    font-size: 14px;
    min-height: 13vh;
    height: 30vh;
    color: #323233;
    background-color: #fff;
    padding: 40px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    overflow-y: auto;
    margin-top: 5%;
    margin-bottom: 15%;
    padding-bottom: 5%;
}
</style>