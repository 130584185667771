import axios from "axios";
import store from "@/store";
import storage from "store";
import { Toast } from "vant";
import { VueAxios } from "./axios";
// import router from '../router/index.js'
// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 600000, // 请求超时时间
});

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    // const data = error.response.data;
    // 从 localstorage 获取 token
    Toast.fail(error.response.statusText);
    if (error.response.status === 401) {
      setTimeout(() => {
        storage.remove("token");
      }, 1500);
    }
    if (error.response.status === 403) {
    }
    if (error.response.status >= 500) {
    }
  }
  return Promise.reject(error);
};

// request interceptor
request.interceptors.request.use((config) => {
  const token = storage.get("token");
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use((response) => {
  const code = response.data.code;
  if (code === 40001) {
    Toast.fail(response.data.message);
    // // store.state.is_show_login = true;
    setTimeout(() => {
      storage.remove("token");
      router.push({ path: "/user/login" });
    }, 1500);
    return null;
  } else if (code > 0) {
    Toast.fail(response.data.message);
    // return null
  }
  return response.data.data;
}, errorHandler);

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request);
  },
};

export default request;

export { installer as VueAxios, request as axios };
