<template>
  <div id="app">
    <van-sticky>
      <van-nav-bar v-if="$route.meta.show_head" :title="$route.meta.title || 'ITInfo-Copilot'" safe-area-inset-top>
        <template slot="left" v-if="$route.meta.show_left">
          <van-icon name="arrow-left" size="20" color="#fff" @click="onClickLeft()" />
        </template>
        <template slot="right" v-if="$route.meta.show_right">
          <van-icon name="user-circle-o" size="28" color="#fff" @click="goCenter()" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <router-view />
    <van-action-sheet v-model="isChangeVersion" :actions="actions" cancel-text="取消" description="请选择AI模型"
      close-on-click-action />
  </div>
</template>
<script>
import { GetAccountInfo } from '@/api/api';
import storage from "store";
export default {
  components: {
  },
  computed: {
  },
  data() {
    return {
      isChangeVersion: false,
      container: null,
      activeNames: [],
      actions: [
        { name: 'GPT3', color: '#ee0a24' },
        { name: 'GPT4', disabled: true },
        { name: 'DALLE', disabled: true, loading: false, subname: 'VIP可用' },
      ],
    }
  },
  mounted() {
    this.container = this.$refs.actions;
    if (storage.get('token')) {
      this.getUserInfo()
    }
  },
  methods: {

    getUserInfo() {
      GetAccountInfo().then(res => {
        if (!res) return
        console.log('更新用户信息', res)
        this.$store.dispatch('updateUserInfo', res)
      })
    },
    onClickLeft() {
      // this.$toast('返回');
      this.$store.dispatch('PageLoadding', true)
      this.$router.go(-1)
      setTimeout(() => {
        this.$store.dispatch('PageLoadding', false)
      }, 300)
    },
    goCenter() {
      this.$router.push('/account')
    },
    versionSelect() {//版本选择
      this.isChangeVersion = true
    }
  },
}
</script>
<style scoped lang="less">
/deep/.van-badge--fixed {
  position: absolute;
  top: -1vh;
  left: -48vw;
  right: unset;
}
</style>
<style lang="less">
.van-nav-bar__content {
  background-color: #4095E5;
}

.van-nav-bar__title {
  color: #fff !important;
}

.app {
  background-color: #ccc;
}

.van-collapse {
  margin: 2% 3% 3% 3%;
  border: solid 1px #ccc;
  border-radius: 4px;
}

html,
body,
#app {
  height: 100%;
  max-height: 100%;
  // background-color: #eaeaea;
  overflow-y: hidden;
}



/deep/::-webkit-scrollbar {
  /* 隐藏滚动条 */
  display: none;
}

.footer {
  // position: absolute;
  bottom: 0;
  padding: 0 16px;
  margin: 48px 0 24px;
  text-align: center;

  .links {
    margin-bottom: 8px;
    font-size: 14px;

    a {
      color: rgba(0, 0, 0, 0.45);
      transition: all 0.3s;

      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }

  .copyright {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.15);
    transition: color 0.2s ease;
  }
</style>