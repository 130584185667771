import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import antai from "./modules/antai";

import getters from "./getters";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    antai,
  },
  state: {},
  mutations: {},
  actions: {},
  getters,
});
